.content {
  padding: 30px;
}

.logo {
  width: 148px;
  height: auto;
}

.header {
  padding-top: 24px;
  padding-bottom: 24px;
}

.navButton {
  padding: 20px 14px;
  font-size: 20px;
  line-height: 26px;
}

.icon {
  margin-right: 24px;
}

[dir=rtl] .logoutBody {
  flex-direction: row-reverse;
}
.logoutIcon {
  margin-right: 10px;
}
