/*rtl:begin:ignore*/
.gridLayout {
  display: grid;
  grid-template-columns: 216px 1fr;
  height: 100%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  height: 100%;

  .content {
    overflow: auto;
    flex-grow: 1;
  }

  .footer {
    padding: 7px 0;
    background: #F5F8FF;
  }

  .footerItem {
    line-height: 4;
  }
}
/*rtl:end:ignore*/
