@import "../../styles/colors.scss";

.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
  padding: 16px 30px;
  background-color: $mainBackgroundColor;

  .top {
    margin-bottom: 24px;
  }

  .topLogoWrapper{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    img {
      width: 84px;
      height: 40px;
    }

    svg {
      cursor: pointer;
      border-radius: 4px;
      width: 40px;
      height: 40px;

      &:hover {
        background-color: $mainBackgroundColorLight;
      }
    }
  }

  .programName {
    font-size: 20px;
    padding-bottom: 16px;
    color: $primaryColor;
    border-bottom: 0.5px solid #A3ABC3;
  }

  .itemList {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    li {
      width: 100%;
    }
  }

  .item {
    display: flex;
    gap: 16px;
    text-decoration: none;
    justify-content: flex-start;
    height: 40px;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    color: $primaryColor;
    cursor: pointer;
    transition: 0.2s;

    &.active,
    &:hover{
      background-color: $primaryColor;
      color: $white;

      .itemIcon svg{
        fill: $primaryColor;
        stroke: $white;
      }
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      color: $disabled;

      .itemIcon svg{
        fill: $disabled;
      }
    }
  }

  .itemIcon {
    width: 24px;
    height: 24px;
  }

  .itemLabel {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.33px;
  }

}
