html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
}

#app {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

/* stylelint-disable-next-line block-no-empty  */
.layout-container {}

.layout-content {
  max-width: 960px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
