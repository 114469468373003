.headerWrapper {
  display: flex;
  align-items: center;
}

.backIcon {
  font-size: 20px;
}
[dir=rtl] .backIcon {
  transform: rotate(180deg);
}
