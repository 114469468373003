.container {
  max-width: 410px;
  min-width: 300px;
  width: 100%;
  height: 394px;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid transparent;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container:hover {
  border: 1px solid #000000;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
}
.fadeIn {
  opacity: 0;
}

.visible {
  transition: opacity 0.5s ease;
  opacity: 1;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.rotateVolun {
  transform: rotate(220deg);
}
.rotateTask {
  transform: rotate(30deg);
}

.displayValue {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
