@import "./reset.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Space Grotesk', 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.label {
  position: static;
  transform: none;
  overflow: hidden;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.arrow {
  display: none!important;
}
.react-tel-input .selected-flag {
  display: flex;
  justify-content: center;
  width: 45px!important;
}
[dir=rtl] .react-tel-input .form-control {
  padding-left: 10px!important;
}
[dir=rtl] .MuiTablePagination-actions {
  margin-right: 20px;
  margin-left: 0;
  .MuiButtonBase-root {
    transform: rotate(180deg);
  }
}
