@import "../../styles/colors.scss";

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: $mainBackgroundColor;
}

.headerTitle {
  font-weight: 700;
  color: $primaryColor;
  font-size: 24px;
  line-height: 30.62px;
}

.headerContent {
  display: flex;
  gap: 30px;
  align-items: center;
}

.headerLogo {
  width: 107px;
  height: 52px;

  img {
    width: 100%;
    height: 100%;
  }
}
