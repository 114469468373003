.container {
  overflow-x: hidden;
  height: 100vh;
  background-image: url("../../../images/heart_background.svg");

  .logo {
    width: 100%;
    max-width: 420px;
    margin-bottom: 32px;
  }

  .content {
    max-width: 580px;
    margin: auto;
    text-align: center;
  }

  .footer {
    height: 100px;
  }
}
